import logoBrand1 from '../../src/images/data/rev-tech-radar 1.png';
import logoBrand2 from '../../src/images/data/rev-t3.png';
import logoBrand3 from '../../src/images/data/rev-trusted-reviews.png';

import logo from '../../src/images/data/tv/logo-glass.jpg';

const q = {
    data : {
        wp: {
            reviews: {
                dataHeader : {
                    home:true, 
                    back:true,
                    logo:logo,
                    buttons : [
                        {
                            links : '/tv/glass',
                            label : 'Discover Sky Glass'
                        },
                        {
                            links : '/tv/glass/configurator',
                            label : 'Choose your TV'
                        }
                    ]
                },
                revCarousel : {
                    titles : {
                        up : 'Reviews we\'re proud of...',
                        down : 'We love it. Our customers love it. We think you\'ll love it too.',
                    },
                    options : [
                        {
                            id: 'q-apps-1',
                            reviews : {
                                up: {
                                    // title: ,
                                    img : logoBrand1,
                                    text: '“Sky Glass represents a paradigm shift in the premium TV industry”'
                                },
                                down: {
                                    // title:,
                                    text: '“Absolutely love the voice control and all favourites content across all channels and apps own one place. The picture and sound are cinema quality. Best TV I have ever owned”.',
                                    who: '- Tracey L' 
                                },
                            }
                        },
                        {
                            id: 'q-apps-2',
                            reviews : {
                                up: {
                                    // title: ,
                                    img : logoBrand1,
                                    text: '“Sky Glass will be just what you\'ve been waiting for - particularly if you are hankering for a 4K TV update”'
                                },
                                down: {
                                    // title:,
                                    text: '“Good quality tv with excellent picture and sound”.',
                                    who: '- Kevin R' 
                                },
                            }
                        },
                        {
                            id: 'q-apps-3',
                            reviews : {
                                up: {
                                    // title: ,
                                    img : logoBrand1,
                                    text: '“Sky Glass is an all-in-one premium TV package that opens the door to a whole new age of streaming TV”'
                                },
                                down: {
                                    // title:,
                                    text: '“Excellent product. The picture sound ease of use is everything I expected. The TV looks great and simple to set up. Great product”.',
                                    who: '- Jonathan W' 
                                },
                            }
                        },
                        {
                            id: 'q-apps-4',
                            reviews : {
                                up: {
                                    // title: ,
                                    img : logoBrand2,
                                    text: '“Sky Glass is the iPhone moment for TV”'
                                },
                                down: {
                                    // title:,
                                    text: '“From the first time I saw Sky Glass I knew it was the way forward for my way of watching TV, and it didn’t disappoint. Form the super smooth delivery, to ease of the set up, Sky have knocked it out of the park with this product.”',
                                    who: '- Shawn H' 
                                },
                            }
                        },
                        {
                            id: 'q-apps-5',
                            reviews : {
                                up: {
                                    // title: ,
                                    img : logoBrand2,
                                    text: '“A serious game-changer for the TV industry”'
                                },
                                down: {
                                    // title:,
                                    text: '“It’s a beautiful piece of functional furniture”',
                                    who: '- Elwyn M' 
                                },
                            }
                        },
                        {
                            id: 'q-apps-6',
                            reviews : {
                                up: {
                                    // title: ,
                                    img : logoBrand2,
                                    text: '“Sky Glass is a landmark change in the history of TV”'
                                },
                                down: {
                                    // title:,
                                    text: '“Love it! Picture quality and sound are amazing! Brings our tv to life! And the puck upstairs is great”',
                                    who: '-  Michelle H' 
                                },
                            }
                        },
                        {
                            id: 'q-apps-7',
                            reviews : {
                                up: {
                                    // title: ,
                                    img : logoBrand2,
                                    text: '“Sky Glass brings the entire Sky package together in one gloriously simple TV package”'
                                },
                                down: {
                                    // title:,
                                    text: '“I feel this is the way forward for TVs as you can customise your TV apps of your choice. Excellent product would highly recommend”',
                                    who: '- Lisa W' 
                                },
                            }
                        },
                        {
                            id: 'q-apps-8',
                            reviews : {
                                up: {
                                    // title: ,
                                    img : logoBrand3,
                                    text: '“Sky Glass brings the entire Sky package together in one gloriously simple TV package”'
                                },
                                down: {
                                    // title:,
                                    text: '“This has been one of the best investments we have ever made. Everything from the picture quality to the sound and contend is fantastic”',
                                    who: '- Kayleigh S' 
                                },
                            }
                        },
                        {
                            id: 'q-apps-9',
                            reviews : {
                                up: {
                                    // title: ,
                                    img : logoBrand3,
                                    text: '“Sky Glass brings the entire Sky package together in one gloriously simple TV package”'
                                },
                                down: {
                                    // title:,
                                    text: '“Fantastic… The future without doubt.”',
                                    who: '- Graham E' 
                                },
                            }
                        },
                        {
                            id: 'q-apps-9',
                            reviews : {
                                up: {
                                    // title: ,
                                    img : logoBrand3,
                                    text: '“Sky Glass brings the entire Sky package together in one gloriously simple TV package”'
                                },
                                down: {
                                    // title:,
                                    text: '“The change over to Sky Glass was the best choice I have made in years”',
                                    who: '- Brian L R' 
                                },
                            }
                        },
                        
                    ]
                }
            }
        },

    }
}

export default q;