import React from "react";
import Carousel from "../../components/editorial/CarouselVideo";
import data from '../../../queries/data/reviews';
import Layout from "../../UI/Layout";

const index = () => {

  const carousel = {
    mainCarousel : [
      ...data.data.wp.reviews.revCarousel.options
    ],
    titles : {
      ...data.data.wp.reviews.revCarousel.titles
    }
  }

  const dataHeader = data.data.wp.reviews.dataHeader;

  return (
    <Layout className="Editorial" dataHeader={dataHeader}>
      <Carousel
        slideType="reviewsSlides"
        noScroll 
        noTitle 
        info={carousel}
        />
    </Layout>
  )
}

export default index;